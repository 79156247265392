<template>
  <div
    v-loading="loading"
    class="custom-dashboard-stats"
    :element-loading-text="loadingText"
  >
    <el-scrollbar>
      <div
        v-if="dashboardstats && dashboardstats.length"
        class="stats-container mb-1 mt-1"
      >
        <draggable
          class="stats-container"
          v-model="dashboardstats"
          :disabled="dragDisabled"
          @end="onDragEnd"
        >
          <div
            class="card custom-dashboard-top-buttons"
            v-for="(stat, index) in dashboardstats"
            :style="`min-width: 100px;background-color: ${stat.background_color};`"
            :key="
              stat.component_name +
              stat.entity_id +
              stat.filter_id +
              'stat' +
              index
            "
          >
            <SingleStat
              :component="stat"
              :name="stat.component_name"
              :count="stat.count"
              :font_color="stat.font_color"
              :entity_id="stat.entity_id"
              :filter_id="stat.filter_id"
              :index="index"
              :hide_options="hide_options"
              :customFilters="customFilters"
              :isFromApplicationuser="isFromApplicationuser"
              :key="stat.entity_id + stat.filter_id + 'stat_compo' + index"
              :getAllCompanyTemplatesData="getAllCompanyTemplatesData"
              @deleteStat="deleteStat"
              @editStat="editStat"
              @disableDrag="disableDrag"
              @openStatDataIntable="openStatInTable"
              :permittedEntities="permittedEntities"
              :permittedRelatedEntities="permittedRelatedEntities"
              :permittedNestedRelatedEntities="permittedNestedRelatedEntities"
              :getAllEntities="getAllEntitiesData"
              :hasViewPermission="checkPermissions(stat.entity_id, 'VIEW')"
              :hasEditPermission="checkPermissions(stat.entity_id, 'EDIT')"
              :hasAddPermission="checkPermissions(stat.entity_id, 'ADD')"
              :hasDeletePermission="checkPermissions(stat.entity_id, 'DELETE')"
              :integrations="integrations"
              :predefinedIntegrations="predefinedIntegrations"
            ></SingleStat>
          </div>
        </draggable>
        <div
          class="card custom-dashboard-top-buttons"
          style="min-width: 100px"
          v-if="!hide_options"
        >
          <div class="new-stat" v-on:click="addDashboardStat">
            <i class="el-icon-plus"></i>
            <span>
              <el-link :underline="false" type="primary"> Add</el-link>
            </span>
          </div>
        </div>
      </div>
      <el-row v-else>
        <el-col>
          <div class="nodata-stat-root">
            <p v-if="hide_options">No stats</p>
            <p class="mt-3" v-else>
              No statistics have been added yet.Click
              <b style="font-weight: bold">Add Stats</b> button to add them.
            </p>
            <el-button v-if="!hide_options" v-on:click="addDashboardStat"
              >Add Stats</el-button
            >
          </div>
        </el-col>
      </el-row>
    </el-scrollbar>
    <div v-if="showDataAsTable && selectedStat" class="p-2">
      <CustomDashboardTable
        v-if="showDataAsTable"
        :component="selectedStat"
        :entity_id="selectedStat.entity_id"
        :filter_id="selectedStat.filter_id"
        :customFilters="customFilters"
        :externalLimit="10"
        :externalPage="1"
        :hide_expend="true"
        :hide_options="true"
        :isFromApplicationuser="isFromApplicationuser"
        :hasViewPermission="checkPermissions(selectedStat.entity_id, 'VIEW')"
        :hasEditPermission="checkPermissions(selectedStat.entity_id, 'EDIT')"
        :hasAddPermission="checkPermissions(selectedStat.entity_id, 'ADD')"
        :hasDeletePermission="
          checkPermissions(selectedStat.entity_id, 'DELETE')
        "
        :getAllCompanyTemplatesData="getAllCompanyTemplatesData"
        :getAllEntities="getAllEntities"
        :permittedEntities="permittedEntities"
        :permittedRelatedEntities="permittedRelatedEntities"
        :permittedNestedRelatedEntities="permittedNestedRelatedEntities"
        :integrationDetails="integrationDetails"
      ></CustomDashboardTable>
    </div>
    <dialog-component
      :visible="addStatsModal"
      :title="'Add Dashboard Stats'"
      @before-close="resetStatsModal"
      :containerWidth="'40%'"
    >
      <div v-loading="loading">
        <CustomComponentEdit
          :addStatsData="addStatsData"
          :allDashboardstats="allDashboardstats"
          :isFromApplicationuser="isFromApplicationuser"
          :getRelationshipEntities="getRelationshipEntities"
          :getNestedRelationshipEntities="getNestedRelationshipEntities"
          :globalVariablesData="globalVariablesData"
          :getAllEntities="getAllEntities"
          :getAllCompanyTemplatesData="getAllCompanyTemplatesData"
          :permittedEntities="permittedEntities"
          :permittedRelatedEntities="permittedRelatedEntities"
          :permittedNestedRelatedEntities="permittedNestedRelatedEntities"
          :integrations="integrations"
          :predefinedIntegrations="predefinedIntegrations"
          v-if="addStatsModal"
        ></CustomComponentEdit>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetStatsModal">Cancel</el-button>
        <el-button
          type="primary"
          @click="addDashbpardStat"
          :disabled="checkDisabled"
          :loading="loading"
          >Save</el-button
        >
      </span>
    </dialog-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { bus } from "../../main";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import * as mexp from "math-expression-evaluator";
import draggable from "vuedraggable";
import moment from "moment";

import { postAPICall } from "@/helpers/httpHelper";
export default {
  mixins: [CustomDashboardConfig],
  components: {
    SingleStat: () => import("./dashboardStat.vue"),
    CustomComponentEdit: () => import("./customComponentEdit.vue"),
    CustomDashboardTable: () => import("./customDashboardTable.vue"),
    draggable,
  },
  data() {
    return {
      allStats: [],
      integrationDetails: null,
      modalLoading: false,
      loading: false,
      tableColums: [],
      currentEntity: null,
      currentFilter: null,
      total: 0,
      currentPage: 1,
      pageSize: 0,
      data: [],
      pageSizes: [5, 10, 15],
      dashboardstats: [],
      constDashboardstats: [],
      addStatsModal: false,
      addStatsData: {
        component_name: "",
        entity_id: "",
        filter_id: "",
        type: "STAT",
        feature: "ENTITY",
        isNew: true,
        icon: "dashboard.svg",
        display_type: "count",
        sum_field: "",
        is_currency: false,
        currency_type: "USD",
        date_filter_field: "",
        ignore_year: false,
        selected_fields: [],
        isVisible: true,
        background_color: "#FFFFFF",
        send_notification: false,
        compare: false,
        sort_by: "",
        sort_order: "asc",
        compare_with: "one",
        data_source: "ENTITIES",
        integration_id: "",
        event: "",
        apply_date_filter: false,
        selected_formbuilder_ids: [],
        formbuilder_filter: "ALL",
        formbuilder_user_filter: "ALL",
      },
      statEntityFilters: [],
      currenEditIndex: -1,
      loadingText: "Fetching stats...",
      customFilters: [],
      requiredStats: [],
      dragDisabled: false,
      showDataAsTable: false,
      selectedStat: null,
      mappedIntegrations: {},
      mappedApplications: {},
    };
  },
  props: {
    allDashboardstats: Object,
    hide_options: Boolean,
    refresh: Boolean,
    isFromApplicationuser: Boolean,
    getRelationshipEntities: Array,
    getNestedRelationshipEntities: Array,
    globalVariablesData: Array,
    permissionsSet: Object,
    getAllEntities: Object,
    getAllCompanyTemplatesData: Object,
    permittedEntities: Array,
    permittedRelatedEntities: Array,
    permittedNestedRelatedEntities: Array,
    integrations: Array,
    predefinedIntegrations: Array,
  },
  computed: {
    ...mapGetters("entities", [
      // "getEntityCreatedStatus",
      // "getEntityCreatedData",
      // "getCustomDashboardStats",
      // "getEntityDataById",
      // "getEntityDataUpdateStatus",
      // "getEntityErrors",
      // "getAllEntities",
      // "getEntityRecordsForTable",
    ]),
    ...mapGetters("filters", [
      // "getSingleFilterData",
      // "getAllEntityFilters"
    ]),
    // ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    // ...mapGetters("auth", ["getGlobalFiltervalue"]),
    getAllEntitiesData() {
      return this.getAllEntities && this.getAllEntities.data
        ? this.getAllEntities.data
        : [];
    },
    checkDisabled() {
      if (!this.addStatsData.component_name) {
        return true;
      } else {
        if (this.addStatsData.data_source == "INTEGRATIONS") {
          return !this.addStatsData.integration_id || !this.addStatsData.event;
        }
        if (this.addStatsData.data_source == "FORM_BUILDERS") {
          return !this.addStatsData?.selected_formbuilder_ids?.length === 0;
        }
        if (this.addStatsData?.display_type == "FORMULA") {
          return this.validate ? false : true;
        } else {
          return (
            !this.addStatsData.entity_id ||
            // !this.addStatsData.filter_id ||
            ((this.addStatsData.display_type == "sum" ||
              this.addStatsData.display_type == "avg") &&
              !this.addStatsData.sum_field)
          );
        }
      }
    },
    validate() {
      let exp = "";
      let lastField = "";
      let err = false;
      let selectedFieldsLength = this.addStatsData.selected_fields.length || 0;
      if (selectedFieldsLength && selectedFieldsLength > 2) {
        this.addStatsData.selected_fields.map((el) => {
          if (el.type == "OPERATOR") {
            exp += el.operator + " ";
            if (lastField != "operator") {
              lastField = "operator";
            } else {
              err = true;
            }
          } else if (el.type == "PARENTHESES") {
            exp += el.parentheses + " ";
            lastField = "";
          } else {
            exp += Math.floor(Math.random() * Math.floor(9)) + " ";
            if (lastField != "number") {
              lastField = "number";
            } else {
              err = true;
            }
          }
          return;
        });
        try {
          if (err) {
            return false;
          } else {
            mexp.eval(exp);
            return true;
          }
        } catch (e) {
          return false;
        }
      } else {
        return false;
      }
    },
    checkGlobalFilterOnStats() {
      if (this.getCompanyDetails?.global_filters) {
        let filter = this.getCompanyDetails.global_filters.find(
          (e) => e.include_in_topbar
        );
        let entityStats = this.allDashboardstats.allStatComponents.flatMap(
          (e) => e.entity_id
        );
        if (
          filter?.apply_on &&
          entityStats.find((el) =>
            filter.apply_on.flatMap((e) => e.split("#")[0]).includes(el)
          )
        ) {
          return filter;
        }
        return null;
      }
      return null;
    },
    getPredefinedApplications() {
      return (this.integrations || []).filter(
        (e) => e.app_type == "PRE_DEFINED"
      );
    },
  },
  watch: {
    "allDashboardstats.allStatComponents"() {
      this.getNecessaryInfo(this.allDashboardstats.allStatComponents, true);
      let tableStat = this.allDashboardstats.allStatComponents.find(
        (e) => e.show_data_in_table
      );
      if (tableStat) {
        this.integrationDetails = this.buildIntegrationsDetails(tableStat);
        this.openStatInTable(tableStat.keyValue, this.integrationDetails);
      } else {
        this.selectedStat = null;
        this.showDataAsTable = false;
      }
    },
    getGlobalFiltervalue: {
      handler() {
        if (this.checkGlobalFilterOnStats?.apply_on) {
          this.getNecessaryInfo(this.allDashboardstats.allStatComponents, true);
        }
      },
    },
  },
  mounted() {
    this.setThirpartyData();
    if (this.allDashboardstats && this.allDashboardstats.allStatComponents) {
      this.getNecessaryInfo(this.allDashboardstats.allStatComponents, true);
      let tableStat = this.allDashboardstats.allStatComponents.find(
        (e) => e.show_data_in_table
      );
      if (tableStat) {
        this.integrationDetails = this.buildIntegrationsDetails(tableStat);
        this.openStatInTable(tableStat.keyValue, this.integrationDetails);
      }
    }
    bus.$on("apply-global-filter", (filter) => {
      let requiredStats = JSON.parse(JSON.stringify(this.allStats.slice()));

      requiredStats = requiredStats.map((stat) => {
        if (
          stat?.apply_global_filter &&
          stat.integration_id &&
          stat.global_filters
        ) {
          let integration = this.getPredefinedApplications.find(
            (e) => e?._id == stat?.integration_id
          );

          if (integration?.connection_id == filter?.filter?.connection_id) {
            stat["dependencies"] = {
              ...stat["dependencies"],

              ...{
                [filter?.filter?.dependency]: filter.value,
              },
            };
          }
        }

        return stat;
      });

      this.getNecessaryInfo(requiredStats, true);
    });
    bus.$on("apply-date-filter", (filter) => {
      let requiredStats = JSON.parse(JSON.stringify(this.allStats.slice()));
      let isReload = false;
      if (filter.reset) {
        requiredStats = requiredStats.map((fl) => {
          if (fl.date_filter_field) {
            if (fl.customFilers && fl.customFilers.length) {
              fl.customFilers = fl.customFilers.filter(
                (e) => e.field != fl.date_filter_field
              );
              isReload = true;
            }
          } else if (fl.apply_date_filter && fl.data_source == "INTEGRATIONS") {
            isReload = true;
          }
          return fl;
        });
      } else if (filter && filter.filter) {
        requiredStats = requiredStats.map((fl) => {
          if (fl.date_filter_field) {
            let newFilter = JSON.parse(JSON.stringify(filter.filter[0]));
            newFilter.field = fl.date_filter_field;
            newFilter.every_year = fl.ignore_year;
            newFilter.entity_id = fl.entity_id;
            if (fl.customFilers && fl.customFilers.length) {
              fl.customFilers = this.updateCommonFilters(fl.customFilers, [
                newFilter,
              ]);
            } else {
              fl.customFilers = [newFilter];
            }
            isReload = true;
          } else if (
            fl.apply_date_filter &&
            fl.data_source == "INTEGRATIONS" &&
            filter.filter[0]
          ) {
            console.log("filter", filter);
            fl.dates = {
              startDate: moment(filter.filter[0].value[0])
                .startOf("day")
                .format("YYYY-MM-DD"),
              endDate: moment(filter.filter[0].value[1])
                .startOf("day")
                .format("YYYY-MM-DD"),
            };
            isReload = true;
          }
          return fl;
        });
      }
      if (isReload) {
        this.getNecessaryInfo(requiredStats, true);
      }
    });
    bus.$on("fetch-all-stats", () => {
      this.allDashboardstats.allStatComponents = this.dashboardstats.map(
        (e) => {
          if (e.customFilers) {
            e.customFilers = [];
          }
          return e;
        }
      );
    });
    bus.$on("apply-custom-filter", (data) => {
      if (data) {
        if (data.reset) {
          if (data.field_id) {
            let requiredStats = JSON.parse(
              JSON.stringify(this.allStats.slice())
            );
            requiredStats = requiredStats.map((e) => {
              if (e.entity_id == data.entity_id) {
                e.customFilers = (e.customFilers || []).filter((el) => {
                  if (el.field != data.field_id) {
                    return el;
                  }
                });
              } else if (data.entity_id.includes(e.entity_id)) {
                e.customFilers = (e.customFilers || []).filter((el) => {
                  return !data.field_id.includes(el.field);
                });
              }
              return e;
            });
            this.getNecessaryInfo(requiredStats, true);
          } else if (data.entity_id) {
            let requiredStats = JSON.parse(
              JSON.stringify(this.dashboardstats.slice())
            );
            requiredStats = requiredStats.map((e) => {
              if (e.entity_id == data.entity_id) {
                e.customFilers = [];
              } else if (data.entity_id.includes(e.entity_id)) {
                e.customFilers = [];
              }
              return e;
            });
            this.getNecessaryInfo(requiredStats, true);
          } else {
            this.getNecessaryInfo(
              this.allDashboardstats.allStatComponents,
              true
            );
          }
        } else if (data.fields && data.fields.length) {
          let requiredStats = JSON.parse(
            JSON.stringify(this.dashboardstats.slice())
          );
          requiredStats = requiredStats.map((fl) => {
            let selectedFilters = data.fields.filter(
              (e) => e.entity_id == fl.entity_id
            );
            if (selectedFilters && selectedFilters.length) {
              if (fl.customFilers && fl.customFilers.length) {
                fl.customFilers = this.updateCommonFilters(
                  fl.customFilers,
                  selectedFilters
                );
              } else {
                fl.customFilers = [...selectedFilters];
              }
            }
            return fl;
          });
          this.getNecessaryInfo(requiredStats, true);
        }
      }
    });
  },
  beforeDestroy() {
    bus.$off("apply-custom-filter", (data) => {
      console.log("data destory", data);
    });

    bus.$off("apply-global-filter", (data) => {
      console.log("data destory", data);
    });
  },
  methods: {
    selectedIntegration(component) {
      if (
        component.data_source === "INTEGRATIONS" &&
        this.integrations.length > 0
      ) {
        const matchingIntegration = this.integrations.find(
          (integration) => integration._id === component.integration_id
        );
        if (matchingIntegration) {
          let application = this.predefinedIntegrations.find(
            (predefinedIntegration) =>
              predefinedIntegration.key == matchingIntegration.application
          );
          if (application) {
            return {
              ...application,
              integration: matchingIntegration,
            };
          }
        }
      }
      return null;
    },
    buildIntegrationsDetails(component) {
      if (component?.data_source === "INTEGRATIONS") {
        let matchedApplication = this.selectedIntegration(component);
        if (component.event && matchedApplication?.events) {
          const eventKey = component.event;
          const matchingEvents = matchedApplication.events.find(
            (event) => event.eventKey === eventKey
          );
          if (
            !matchingEvents?.settings?.supportType ||
            matchingEvents?.settings?.supportType === "stat"
          ) {
            return null;
          } else {
            return {
              matchedApplication,
              matchingEvents,
            };
          }
        }
      }
      return null;
    },
    setThirpartyData() {
      (this.integrations || []).forEach((e) => {
        this.mappedIntegrations[e._id] = e;
      });
      (this.predefinedIntegrations || []).forEach((e) => {
        this.mappedApplications[e.key] = e;
      });
    },
    onDragEnd() {
      this.$emit("update-dashboardstats", this.dashboardstats);
    },
    openStatInTable(keyValue,integrationDetails) {
      if (keyValue == this.selectedStat?.keyValue) {
        return;
      }
      let tableStat = this.allDashboardstats.allStatComponents.find(
        (e) => e.keyValue == keyValue
      );
      if (tableStat) {
        this.resetStatTable();
        setTimeout(() => {
          if(integrationDetails){
         this.integrationDetails = integrationDetails;
        }
          this.selectedStat = tableStat;
          this.showDataAsTable = true;
        }, 100);
      }
    },
    resetStatTable() {
      this.selectedStat = null;
      this.showDataAsTable = false;
      this.integrationDetails = null;
    },
    disableDrag(isDrag) {
      this.dragDisabled = isDrag;
    },
    checkPermissions(entity, permission) {
      if (this.isFromApplicationuser) {
        if (
          this.permissionsSet &&
          Object.keys(this.permissionsSet).length > 0
        ) {
          if (
            this.permissionsSet[entity] &&
            this.permissionsSet[entity].indexOf(permission) != -1
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      return true;
    },
    editStat(index) {
      this.currenEditIndex = index;
      this.addStatsData = JSON.parse(
        JSON.stringify(this.dashboardstats[index])
      );
      this.addStatsData = {
        ...{
          isVisible: true,
          background_color: "#FFFFFF",
          compare: false,
          sort_by: "",
          sort_order: "asc",
          compare_with: "one",
        },
        ...this.addStatsData,
      };
      this.addDashboardStat();
    },
    deleteStat(index) {
      this.dashboardstats.splice(index, 1);
      this.allDashboardstats.allStatComponents = this.dashboardstats;
      this.$emit("addIntegrationsFilters", this.dashboardstats);
    },
    async addDashbpardStat() {
      this.loading = true;
      let ex = this.allDashboardstats.allStatComponents.filter(
        (e, i) =>
          (this.currenEditIndex == -1 ||
            (i !== this.currenEditIndex && this.currenEditIndex > -1)) &&
          e.component_name.toLowerCase() ==
            this.addStatsData.component_name.toLowerCase()
      );
      if (ex && ex.length) {
        this.$notify.error({
          title: "Error",
          message: "Stat name already exists",
        });
        this.loading = false;
        return;
      }
      this.addStatsData.component_name =
        this.addStatsData.component_name.trim();
      if (!this.addStatsData.component_name) {
        this.$message.error("Please enter Stat name");
        this.loading = false;
        return;
      }
      if (this.currenEditIndex > -1) {
        if (this.requiredStats) {
          let filters = this.requiredStats.find(
            (e) =>
              e.entity_id ==
                this.dashboardstats[this.currenEditIndex].entity_id &&
              e.filter_id ==
                this.dashboardstats[this.currenEditIndex].filter_id &&
              e.keyValue == this.dashboardstats[this.currenEditIndex].keyValue
          );
          if (filters && filters.customFilers) {
            this.addStatsData.customFilers = filters.customFilers;
          }
        }
        if (
          this.dashboardstats[this.currenEditIndex] &&
          (this.dashboardstats[this.currenEditIndex].entity_id !=
            this.addStatsData.entity_id ||
            this.dashboardstats[this.currenEditIndex].filter_id !=
              this.addStatsData.filter_id)
        ) {
          await this.getNecessaryInfo(
            [
              {
                ...this.addStatsData,
              },
            ],
            false
          );
        } else {
          this.dashboardstats[this.currenEditIndex] = {
            ...this.addStatsData,
          };
          this.loading = false;
        }
      } else {
        await this.getNecessaryInfo(
          [
            {
              ...this.addStatsData,
              ...{ keyValue: (Math.random() + 1).toString(36).substring(7) },
            },
          ],
          false
        );
      }
      if (this.addStatsData?.data_source == "INTEGRATIONS") {
        this.$emit("addIntegrationsFilters", this.dashboardstats);
      }
      this.resetStatsModal();
      this.allDashboardstats.allStatComponents = this.dashboardstats;
    },
    resetStatsModal() {
      this.currenEditIndex = -1;
      this.addStatsData.component_name = "";
      this.addStatsData.entity_id = "";
      this.addStatsData.filter_id = "";
      this.addStatsData.sum_field = "";
      this.statEntityFilters = [];
      this.addStatsModal = false;
      this.addStatsData.date_filter_field = "";
      this.addStatsData.selected_fields = "";
      this.addStatsData.data_source = "ENTITIES";
      this.addStatsData.selected_formbuilder_ids = [];
      this.addStatsData.formbuilder_filter = "ALL";
      this.addStatsData.formbuilder_user_filter = "ALL";
      this.addStatsData.integration_id = "";
      this.addStatsData.event = false;
      this.addStatsData.apply_global_filter = false;
      this.addStatsData.global_filters = [];
    },
    addDashboardStat() {
      if (!this.hide_options) {
        this.addStatsModal = true;
      }
    },
    async applyRelationalFilters(stats) {
      try {
        return await Promise.all(
          stats.map(async (t) => {
            let e = JSON.parse(JSON.stringify(t));
            if (e?.is_relational_data) {
              e.customFilers = this.applyRelationalFilter(e.customFilers);
              e.customFilers.map((el) => (el.entity_id = e.entity_id));
            } else if (
              e?.is_nested_relational_data &&
              e?.selectedEntityNestedRelationalData
            ) {
              e.customFilers = await this.applyNestedRelationalFilter(
                e.customFilers,
                e
              );
            }
            if (
              this.checkGlobalFilterOnStats?.apply_on &&
              this.checkGlobalFilterOnSingleStat(e.entity_id)
            ) {
              e.customFilers = this.addGlobalFilters(
                e.customFilers || [],
                e.entity_id,
                this.checkGlobalFilterOnSingleStat(e.entity_id)
              );
            }
            return e;
          })
        );
      } catch (e) {
        console.log("applyRelationalFilters", e);
      }
    },
    setThirpartyColumns(stats) {
      return stats.map((stat) => {
        if (
          stat.data_source === "INTEGRATIONS" &&
          this.mappedIntegrations?.[stat?.integration_id]?.application &&
          this.mappedApplications?.[
            this.mappedIntegrations[stat?.integration_id]?.application
          ]?.events
        ) {
          let event = this.mappedApplications?.[
            this.mappedIntegrations[stat?.integration_id]?.application
          ].events.find((e) => e.asset == stat.asset);
          if (event?.settings?.availableColumns) {
            stat.availableColumns = event.settings.availableColumns;
            stat.availableColumns.map((field) => field.key);
          }
        }
        stat.availableFields = stat.availableColumns
          ? stat.availableColumns.map((field) => field.key) // Extract only keys
          : [];
        return stat;
      });
    },
    async getNecessaryInfo(params, isNew) {
      this.loading = true;
      let body = {};
      body.data = await this.applyRelationalFilters(params);
      body.data = this.setThirpartyColumns(body.data);
      this.customFilters = [];
      this.customFilters = params;
      // await this.$store.dispatch("entities/fetchCustomDashboardStats", body);
      const getCustomDashboardStats = await postAPICall(
        "POST",
        "entities-data/count",
        body
      );

      body.data.forEach((item) => {
        if (item.data_source === "INTEGRATIONS") {
          if (item.count !== null && item.count !== undefined) {
            item.count = parseFloat(item.count).toFixed(item.numberValue);
          } else {
            item.count = parseFloat(item.count); // Show normal count without formatting
          }
        }
      });
      if (getCustomDashboardStats?.data) {
        if (this.currenEditIndex === -1) {
          if (isNew) {
            this.dashboardstats = [];
            this.dashboardstats = [...getCustomDashboardStats.data];
          } else {
            this.dashboardstats = [
              ...this.dashboardstats,
              ...getCustomDashboardStats.data,
            ];
          }
        } else {
          this.dashboardstats[this.currenEditIndex] = {
            ...getCustomDashboardStats.data[0],
          };
        }
        this.dashboardstats = this.dashboardstats.map((stat) => {
          if (stat.data_source === "INTEGRATIONS") {
            if (
              stat.count !== null &&
              stat.count !== undefined &&
              stat.numberValue !== undefined
            ) {
              stat.count = parseFloat(stat.count).toFixed(stat.numberValue);
            } else {
              stat.count = parseFloat(stat.count);
            }
          }
          return stat;
        });
        this.dashboardstats = this.applyFormulas(this.dashboardstats);
        this.dashboardstats = this.dashboardstats.map((stat) => {
          return {
            ...{ isVisible: true, background_color: "#FFFFFF" },
            ...stat,
          };
        });
        this.allStats = JSON.parse(JSON.stringify(this.dashboardstats));
        if (this.allStats.length) {
          this.$emit("DateFilterUpdate");
        }
        if (this.hide_options) {
          this.dashboardstats = this.dashboardstats.filter(
            (stat) => stat.isVisible
          );
        }
      }
      this.loading = false;
    },
    applyFormulas(stats) {
      let data = {};
      stats.forEach((e) => {
        data[e.keyValue] = e.count;
      });
      return stats.map((e) => {
        if (e?.display_type == "FORMULA") {
          e.count = this.getFormulaValue(data, e);
        }
        return e;
      });
    },
    getFormulaValue(data, field) {
      if (field?.selected_fields && field.selected_fields.length) {
        let expr = "";
        field.selected_fields.forEach((e) => {
          if (e.type == "FIELD") {
            let v = data[e.key];
            expr = expr + v;
          } else if (e.type == "OPERATOR") {
            expr = expr + e.operator;
          } else if (e.type == "PARENTHESES") {
            expr = expr + e.parentheses;
          } else if (e.type == "NUMBER") {
            expr = expr + e.number;
          }
        });
        try {
          let calculatedValue = mexp.eval(expr);
          let roundedValue = calculatedValue.toFixed(2);
          roundedValue = parseFloat(roundedValue);
          return roundedValue;
        } catch (err) {
          return 0;
        }
      }
      return 0;
    },
    getStateName(title) {
      if (title && title.length) {
        return title;
      } else {
        let name = "";
        if (this.currentEntity && this.currentEntity.name) {
          name = this.currentEntity.name;
        }
        if (this.currentFilter && this.currentFilter.filter_name) {
          name = name + " - " + this.currentFilter.filter_name;
        }
        return name;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.custom-dashboard-top-buttons {
  width: 160px !important;
  height: 63px !important;
  min-width: 140px !important;
  .imgstyle {
    margin-left: 83px !important;
  }
  .number_sty {
    margin-top: -26px !important;
  }
}
.new-stat {
  text-align: center !important;
  i,
  span {
    margin-top: 15% !important;
  }
}
.stats-container {
  // height:85px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  .card {
    padding-left: 15px;
    margin-left: 1px;
    margin-bottom: 5px;
    width: 30px;
    border-radius: 10px;
    min-width: 170px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border-color: #e3eafd;
    // &:hover {
    //   background-color: #f4f7ff;
    // }
    // &.active {
    //   background-color: #ebeffd;
    //   border-color: #d2dcfa;
    // }
  }
  .latest-updates {
    font-size: 0.85em;
  }
}
.icon-block {
  display: flex;
  gap: 10px;
  .icon-file {
    max-width: 20px;
    display: inline;
  }
}
.nodata-stat-root {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  color: #9f9f9f;
  cursor: pointer;
  height: 95px;
  text-align: center;
}
.custom-dashboard-stats {
  max-width: 100%;
  // border: 1px solid #eaeaea;
  // border-top: 1px solid #eaeaea;
  // border-right: 1px solid #eaeaea;
  // border-left: 1px solid #eaeaea;
  // min-height: 250px !important;
  // margin-bottom: 30px;
}
.drag-grid-stats {
}
</style>
